<script lang="ts">
    import LogoColors from '$lib/components/assets/LogoColors.svelte';

    export let size: 'nav' | 'home' | 'midi' = 'nav'
</script>

<div class="logo {size}">

    <div class="logo-colors">
        <LogoColors/>
    </div>

    <div class="logo-vocabeo">
        vocabeo
    </div>

</div>


<!--suppress CssUnusedSymbol -->
<style>
    .logo {
        display: flex;
        align-items: flex-end;
    }

    .logo.nav .logo-colors {
        width: 2.2rem;
        height: 2.2rem;
        margin-left: .1rem;
    }

    .logo.midi .logo-colors {
        width: 3.3rem;
        height: 3.3rem;
        margin-left: .1rem;
    }

    .logo.home .logo-colors {
        width: 4.4rem;
        height: 4.4rem;
    }

    .logo.nav .logo-vocabeo {
        font-size: 27px;
        line-height: .95;
        margin-left: .33rem;
    }

    .logo.midi .logo-vocabeo {
        font-size: 2.6rem;
        line-height: .95;
        margin-left: .5rem;
    }

    .logo.home .logo-vocabeo {
        font-size: 3rem;
        line-height: 1.05;
        margin-left: .8rem;
    }

    .logo-vocabeo {
        font-weight: var(--font-extra-bold);
    }

    @media (max-width: 1000px) {

        .logo.home .logo-colors {
            width: 3rem;
            height: 3rem;
        }

        .logo.home .logo-vocabeo {
            font-size: 2.2rem;
            line-height: 1;
            margin-left: .6rem;
        }

    }

</style>