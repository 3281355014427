import type {WordId} from "$lib/classes/data/_primitives";
import {timer} from "$lib/utils/timer";
import {ASSETS_HOST} from "../../consts";

export type WordIdChanges = Record<WordId, WordId>;

export let wordIdChanges: WordIdChanges = {}

export async function loadWordIdChanges() {
    const timer_word_id_changes = timer('load wordIdChanges')
    try {
        const response = await fetch(`${ASSETS_HOST}/wids.json`)
        if (response.ok) {
            Object.assign(wordIdChanges, await response.json())
        } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`!response.ok ${response.status} ${response.statusText}`)
        }
    } catch (error) {
        throw new Error('loadWordIdChanges', {cause: error})
    }
    timer_word_id_changes()
}