<!--suppress HtmlUnknownAttribute -->
<script lang="ts">
    import {svgContents} from './svg-contents';

    export let name: keyof typeof svgContents
    export let viewBox = '0 0 32 32'
    export let width: string | undefined = undefined
    export let height: string | undefined = undefined
    export let fill = 'currentcolor'
    export let background: string | undefined = undefined
    export let border: string | undefined = undefined
    export let style: string | undefined = undefined
</script>

<!-- svelte-ignore a11y-click-events-have-key-events  a11y-no-static-element-interactions -->
<svg xmlns="http://www.w3.org/2000/svg" {width} {height} {viewBox}
     style="fill-rule:evenodd; {style}"
     {fill}
     style:background
     style:border
     on:mouseover
     on:focus
     on:mouseout
     on:blur
     on:click

     tabindex="-1"
>
    {@html svgContents[name]}
</svg>


<!--suppress CssUnresolvedCustomProperty -->
<style>
    svg {
        display: block;
        transition: fill 100ms;
    }

    :focus {
        outline: none;
    }
</style>