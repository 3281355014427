<script lang="ts">
    import {onDestroy} from 'svelte';
    import Search from '$lib/components/browse/Filter/Search.svelte';
    import WordCountMobile from '$lib/components/browse/Filter/WordCountMobile.svelte';
    import SvgIcon from '$lib/components/assets/SvgIcon.svelte';
    import AllWordsUserWords from '$lib/components/browse/Filter/AllWordsUserWords.svelte';
    import HorizontalRadioBtnsWordClass from '$lib/components/browse/Filter/HorizontalRadioBtnsWordClass.svelte';
    import HorizontalRadioBtns from '$lib/components/browse/Filter/HorizontalRadioBtns.svelte';
    import ResetFilterButton from '$lib/components/browse/Filter/ResetFilterButton.svelte';
    import {clickOutside} from '$lib/utils/click-outside';
    import type {FilterSettings} from '$lib/stores/filterSettings';
    import type {FilterSettingsFiltered} from '$lib/stores/filterSettings_filtered';
    import type {FilterOptions} from '$lib/data/filterOptions';
    import type {UserWordsMapStore} from '$lib/classes/stores/get-user-words-map';
    import type {DisplayWords} from '$lib/stores/displayWords';

    export let filterSettings: FilterSettings
    export let filterSettingsFiltered: FilterSettingsFiltered
    export let filterOptions: FilterOptions
    export let userWordsMap: UserWordsMapStore
    export let displayWords: DisplayWords

    onDestroy(() => {
        unsubFilterSettings()
        filterSettings.resetAll()
        filterOpen = false
    })

    const unsubFilterSettings = filterSettings.subscribe(filterSettings => {
        displayWords.filter(filterSettings, $userWordsMap)
    });

    let filterOpen = false
</script>

<div id="filter-mobile">

    <Search
            displayWords={displayWords}
            filterSettings={filterSettings}
            filtered={filterSettingsFiltered}
    />

    <div id="toggle-filter">
        <div id="filter-text">Filter</div>
        <label for="toggle-filter-icon"
               id="filter-icon-wrapper"
               data-testid="toggle-filter-options"
               style="border-color: {filterOpen ? 'var(--color-accent)' : ''}"
               class:filter-active={$filterSettingsFiltered}
        >
            <SvgIcon name="filter" width="30px" height="30px"
                     viewBox="-6 -6 44 44"
                     fill={'var(--color-accent)'}
            />
        </label>
        <input type="checkbox"
               id="toggle-filter-icon"
               bind:checked={filterOpen}
               hidden
        >
    </div>

    {#if filterOpen}
        <div id="mobile-filter-options" use:clickOutside={'filter-icon-wrapper'} on:outclick={() => filterOpen = false}>

            <div id="display-words-count"
                 data-testid="display-words-count"
            >
                <div id="display-words-count-number">
                    {$displayWords.length.toLocaleString()}
                </div>
                <div id="display-words-count-label">
                    &nbsp;words
                </div>
            </div>

            <AllWordsUserWords
                    filterSettings={filterSettings}
                    bind:value={$filterSettings.words}
            />

            <HorizontalRadioBtnsWordClass options={filterOptions.art} setName="Word class"
                                          bind:value={$filterSettings.art}/>

            <HorizontalRadioBtns options={filterOptions.level.concat('-')} setName="Level"
                                 bind:value={$filterSettings.level}
                                 testId="filter-for-level"
            />

            <div id="mobile-filter-btns">
                <ResetFilterButton
                        filterSettings={filterSettings}
                        filtered={filterSettingsFiltered}
                />
                <button on:click={() => filterOpen = false}>
                    ✓
                </button>
            </div>
        </div>
    {/if}

    <WordCountMobile
            savedWordsCount={Object.values($userWordsMap).length.toLocaleString()}
    />
</div>


<style>

    /*noinspection CssUnusedSymbol*/
    #filter-mobile :global(.filter-active) {
        /*border: 2px solid var(--color-accent);*/
        box-shadow: 0 0 0 2px rgba(202, 139, 22, .5);
    }

    #filter-mobile {
        position: relative;
        display: grid;
        grid-template-columns: 1.5fr min-content min-content;
        padding: .4rem 1rem .4rem .6rem;
        background: var(--color-lighter);
        border-bottom: 1px solid var(--color-dark);
    }

    #toggle-filter {
        margin-left: .4rem;
        margin-right: .4rem;
    }

    #filter-text {
        flex-direction: column;
        display: flex;
        font-size: .8rem;
        font-weight: var(--font-emph);
        margin-left: .2rem;
        margin-bottom: .1rem;
    }

    #filter-icon-wrapper {
        padding: 0 .3rem;
        display: flex;
        background: white;
        border-radius: var(--border-radius);
        border: 2px solid var(--color-medium);
    }

    label :global(svg) {
        /*display: block;*/
        rotate: 90deg;
    }

    #mobile-filter-options {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(0, 100%);
        width: 16rem;
        padding: .6rem 1.4rem 1.2rem 1.4rem;
        background: var(--bg);
        border-radius: var(--border-radius);
        border: 1px solid var(--color-medium);
        box-shadow: var(--shadow-big);
        z-index: var(--z-index-filter-mobile);
    }

    /*noinspection CssUnusedSymbol*/
    #mobile-filter-options :global(#display-words-count + .setName) {
        margin-top: 0;
        line-height: 1;
    }

    #display-words-count {
        padding-right: 2px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        transform: translateY(.3rem);
    }

    #display-words-count-label {
        font-weight: normal;
        font-size: .8rem;
        color: var(--color-accent);
    }

    #display-words-count-number {
        font-size: 1.15rem;
        color: var(--color-accent);
        filter: brightness(.85);
        font-weight: var(--font-bold);
    }

    #mobile-filter-btns {
        display: flex;
        align-items: flex-end;
    }

    #mobile-filter-btns button {
        margin: 0 0 0 .5rem;
        padding: 8px 33px;
        white-space: nowrap;
        font-size: .9rem;
        font-weight: var(--font-emph);
        background: var(--color-accent);
        color: white;
    }

    @media (prefers-color-scheme: dark) {
        #filter-mobile {
            background: var(--bg);
            border-bottom-width: 2px;
        }

        #filter-icon-wrapper {
            background: var(--color-dark-2);
        }

        #filter-icon-wrapper :global(svg) {
            fill: var(--grey-40);
        }
    }
</style>
