export type Testimonial = {
    name: string,
    date: string,
    text: string,
    imgSrc: string,
    src: 'reddit' | 'vocabeo',
    long: boolean,
}

export const testimonials: Testimonial[] = [
    {
        name: 'Joy Ayento',
        date: '2024-12-11',
        text: 'Just started using it really just within an hour and absolutely loved it right away. I get to learn and review vocabularies and at the same time better understand grammar through the examples.',
        imgSrc: 'avatar_vocabeo',
        src: 'vocabeo',
        long: false,
    },
    {
        name: 'Basak Deniz Yildiz',
        date: '2024-11-11',
        text: 'I found your website thanks to your reddit response to some vocabulary related post. The great thing is I found it while looking for a stable resource that lists vocabulary with frequency however that is also not a physical book. I cant say how surprised I was with how specific your website met my demands. Keep up the good work, we are waiting for the new features.',
        imgSrc: 'avatar_vocabeo',
        src: 'vocabeo',
        long: true,
    },
    {
        name: '_AsyA_',
        date: '2024-10-29T15:23:45.116Z',
        text: 'I discovered your website through your post and I can‘t emphasize how helpful it is! I have been using it for nearly half a month (I already had B1 knowledge) and the words that I usually mix up or forget stick with me a lot more! I haven‘t yet gone a day without using it and it helped me more than flashcards can ever do. I especially saw the difference during writing exercises. The example sentences help you remember words more easily than simply seeing them on flashcards by themselves. I think pairing it with a dictionary helps as well to see the declensions and conjugations while you learn new words. Thank you very much for creating such a wonderful website! Would love to also see it as an app.',
        imgSrc: 'asya',
        src: 'reddit',
        long: true,
    },
    {
        name: 'zaza1212420',
        date: '2024-07-13T14:00:14.126Z',
        text: 'Wow. What a shame this is overlooked by people who recommend Anki, hell, even lingvist and duolingo. I will be recommending this, and I hope you continue to promote as I see no flaws so far. Type to answer (my personal favorite) while also highlighting the word in case you can\'t pinpoint and/or don\'t feel like deciphering the exact word missing. Statistics for dopamine. Sort by word classes (German the language of verbs). It has everything. I\'m confident I\'ll be able to read the more adult-oriented books that will skyrocket my fluency in a short amount of time thanks to this. Heavenly. Thank you.',
        imgSrc: 'avatar_reddit',
        src: 'reddit',
        long: true,
    },
    {
        name: 'silicone_river',
        date: '2024-04-16T18:16:01.414Z',
        text: 'Trully excellent resource - really made a difference when approaching my A2 German test. Which I found out that I passed just a couple of weeks ago. Over the moon, really!',
        imgSrc: 'silicone_river',
        src: 'reddit',
        long: false,
    },
    {
        name: 'XiongGuir',
        date: '2024-03-08T15:43:19.379Z',
        text: 'After wading through a ton of anki decks of various levels, voice-over plugins, context, constant flashcard edit, I came to conclusion this is the most quality resource I\'ve seen so far... Thank you so much for building it! 7 Euro / month seems very reasonable.',
        imgSrc: 'xiong_guir',
        src: 'reddit',
        long: false,
    },
    {
        name: 'ForbiddenBeing',
        date: '2023-08-22T02:17:07.915Z',
        text: 'This is genius. I rarely comment but I really had to thank you for this. Seriously!',
        imgSrc: 'forbidden_being',
        src: 'reddit',
        long: false,
    },
]