export async function getIpAddress() {
    try {
        const res = await fetch('https://api.ipify.org?format=json');
        if (res.ok) {
            const data = await res.json();
            return data.ip as string;
        } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`!res.ok - status ${res.status}`)
        }
    } catch (error) {
        console.error('ERROR getting ip address', error)
        return undefined
    }
}