// TODO make ts
import type {UserWordChange} from "$lib/classes/data/user-word-change";
import type {WordIdToWord} from "$lib/data/wordIdToWord";
import type {WordIdToSentences} from "$lib/data/wordIdToSentences";
import type {WordIdChanges} from "$lib/data/word-id-changes";

export function validateUserWordChanges(
    changes: UserWordChange[],
    wordIdToWord: WordIdToWord,
    wordIdToSentences: WordIdToSentences,
    wordIdChanges: WordIdChanges
) {

    const validated = []
    const toSave = []
    const toDelete = []

    for (let change of changes) {

        let userWord = change.userWord

        const foundWord = wordIdToWord[userWord.id]

        if (!foundWord) {
            console.warn('NO Word found for wordId >>', userWord.id)
            toDelete.push(userWord)
            const newWordId = wordIdChanges[userWord.id]
            if (newWordId) {
                console.warn('NEW wordId >>', newWordId)
                userWord = userWord.changedId(newWordId)
            } else {
                console.warn('NO new wordId found')
                continue
            }
        }

        const sentenceId = userWord.sentenceId
        const sentences = wordIdToSentences[userWord.id] ?? []
        const sentenceWithSavedIdFound = sentences.some(sentence => sentence.id === sentenceId)
        const wordIdHasSentences = sentences.length > 0
        const newSentenceId = wordIdHasSentences ? sentences[0].id : null

        if ((sentenceId && sentenceWithSavedIdFound) || (!sentenceId && !wordIdHasSentences)) {
            if (foundWord) {
                validated.push(userWord)
            } else {
                toSave.push(userWord)
            }
        } else if ((sentenceId && !sentenceWithSavedIdFound)) {
            console.warn('NO Sentence with id >> ', sentenceId, " found containing ", userWord.id,
                wordIdHasSentences ? ` >> new sentence: ${sentences[0].sentence}` : ` >> NO other sentence found`)
            userWord.sentenceId = newSentenceId
            toSave.push(userWord)
        } else if (!sentenceId && wordIdHasSentences) {
            console.warn('Sentence ADDED for >> ', userWord.id, ' >> ', sentences[0].sentence)
            userWord.sentenceId = newSentenceId
            toSave.push(userWord)
        }
    }

    return {validated, toSave, toDelete}
}