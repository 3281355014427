<script lang="ts">
    import SvgIcon from "$lib/components/assets/SvgIcon.svelte";
    import type {FilterSettings} from '$lib/stores/filterSettings';

    export let filterSettings: FilterSettings
    export let value = "";

    function resetOtherFilter() {
        filterSettings.resetStatic()
    }

</script>

<div class="setName">Words</div>

<div id="horizontal-radio-btns-user-words"
     data-testid="filter-words"
>
    <div class="radio-btn">
        <label class:selected={value === ''}
               data-testid="all"
        >
            All
            <input type="radio" name="filter-user-words" value="" bind:group={value}>
        </label>
    </div>
    <div class="radio-btn">
        <label id="filter-words-saved" class:selected={value === 'userWords'}
               data-testid="saved"
        >
            Saved
            <input type="radio" name="filter-user-words" value="userWords" bind:group={value}
                   on:change={resetOtherFilter}>
        </label>
    </div>
    <div class="radio-btn">
        <label id="filter-words-known" class:selected={value === 'KNOWN'}
               data-testid="known"
        >
            <SvgIcon name="known" width="24" height="24" viewBox="0 0 32 32"
                     fill="{value === 'KNOWN' ? 'white' : 'currentcolor'}"/>
            <input type="radio" name="filter-user-words" value="KNOWN" bind:group={value} on:change={resetOtherFilter}>
        </label>
    </div>
    <div class="radio-btn">
        <label id="filter-words-learn" class:selected={value === 'LEARN'}
               data-testid="learn"
        >
            <SvgIcon name="learn" width="23" height="23" viewBox="0 0 32 32"
                     fill="{value === 'LEARN' ? 'white' : 'currentcolor'}"/>
            <input type="radio" name="filter-user-words" value="LEARN" bind:group={value} on:change={resetOtherFilter}>
        </label>
    </div>
    <div class="radio-btn">
        <label class:selected={value === 'rest'}
               data-testid="unsaved"
        >
            Rest
            <input type="radio" name="filter-user-words" value="rest" bind:group={value}
                   on:change={resetOtherFilter}>
        </label>
    </div>
</div>


<style>
    .setName {
        margin-top: var(--margin-big);
        margin-bottom: var(--margin-medium);
        font-size: .9rem;
        font-weight: var(--font-emph);
    }

    #horizontal-radio-btns-user-words {
        display: flex;
        height: 2rem;
        flex-shrink: 0;
    }

    .radio-btn {
        width: 100%;
    }

    .radio-btn:first-child label {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    .radio-btn:last-child label {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
        border-right: 0;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        z-index: -1;
        appearance: none;
    }

    label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 .2rem;
        font-size: .95rem;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        border-right: 2px solid var(--color-dark);
        white-space: nowrap;
        background: var(--color-light);
        transition: background 200ms, border 200ms;
        /*border: 1px solid blue;*/
    }

    .selected {
        font-weight: var(--font-emph);
        background: var(--color-accent);
        color: var(--color-light);
    }

    @media (prefers-color-scheme: dark) {
        .selected {
            color: var(--color-dark-2);
        }
    }

</style>