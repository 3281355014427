import type {
    WordId,
    SentenceId,
    Timestamp,
    Interval
} from "$lib/classes/data/_primitives";

export enum Status {
    Known = 'KNOWN',
    Learn = 'LEARN',
}

export class UserWord {
    public repeating? = false

    constructor(
        public readonly id: WordId,
        public status: Status,
        public sentenceId: SentenceId | null,
        public readonly added: Timestamp,
        public toLearnNext: Timestamp,
        public interval: Interval = 54000000,
    ) {}

    deepCopy(): UserWord {
        return new UserWord(this.id, this.status, this.sentenceId, this.added, this.toLearnNext, this.interval);
    }

    changedId(newId: WordId): UserWord {
        return new UserWord(newId, this.status, this.sentenceId, this.added, this.toLearnNext, this.interval);
    }

    toggleStatus() {
        switch (this.status) {
            case Status.Known:
                this.status = Status.Learn;
                break;
            case Status.Learn:
                this.status = Status.Known;
                break;
        }
    }
}