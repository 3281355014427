import pako from 'pako';
import Pako from "pako";

export interface Compressor {
    inflate(uint8Arr: Pako.Data): string

    deflate(string: string): Uint8Array
}

export const compressor: Compressor = {

    inflate(uint8Arr: Pako.Data) {
        return pako.inflate(uint8Arr, {to: 'string'})
    },

    deflate(string: string) {
        return pako.deflate(string)
    }
}
