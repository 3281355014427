import type {Word} from "$lib/classes/data/word";

export type FilterOptions = {
    art: string[]
    level: string[]
}

export let filterOptions: FilterOptions = {
    art: [],
    level: []
}

export function initFilterOptions(allWords: Word[]) {
    // filterOptions =  buildfilterOptions(allWords)

    // Word class
    filterOptions.art = Array.from(new Set(allWords.map(word => word.art)))
        .sort((a, b) => a.localeCompare(b))

    // Level
    filterOptions.level = Array.from(new Set(allWords.map(word => word.base.level.toString())))
        .filter(l => l !== 'NOLEVEL')
        .sort((a, b) => a.localeCompare(b))
}


