// noinspection ES6UnusedImports

import type {FirebaseApp} from 'firebase/app';
import type {Functions as FirebaseFunctions, HttpsCallable, HttpsCallableResult} from 'firebase/functions';
import {getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';
import {browser} from "$app/environment";

export class Functions {
    functions: FirebaseFunctions

    constructor(firebaseApp: FirebaseApp) {
        this.functions = getFunctions(firebaseApp, 'europe-west2');

        // if (browser && location.hostname === 'localhost') {
        //     connectFunctionsEmulator(functions, "localhost", 5005);
        // }
    }

    async emailAuthLink(data: { email: string, url: string }) {
        const callFn = httpsCallable(this.functions, 'emailAuthLink')
        await callFn(data)
    }

    async sendEmail(data: { message: string, userEmail: string }) {
        const callFn = httpsCallable(this.functions, 'sendFeedbackEmail')
        await callFn(data)
    }

    async resetWords() {
        const callFn = httpsCallable(this.functions, 'resetWords')
        await callFn()
    }

    async cancelSubscription(subscriptionId: string) {
        const callFn = httpsCallable(this.functions, 'paddleCancelSubscription')
        await callFn({subscriptionId})
    }

    async reactivateSubscription(subscriptionId: string) {
        const callFn = httpsCallable(this.functions, 'paddleReactivateSubscription')
        await callFn({subscriptionId})
    }

    // TODO refactor: return object instead of callableResult
    async getPaymentMethodUpdateTransaction(subscriptionId: string): Promise<HttpsCallableResult<{ id: string }>> {
        const callFn = httpsCallable(this.functions, 'paddleGetPaymentMethodUpdateTransaction') as
            HttpsCallable<{ subscriptionId: string }, { id: string }>
        return await callFn({subscriptionId})
    }

    async getCustomerId(email: string) {
        const callFn = httpsCallable<{ email: string }, string | undefined>(this.functions, 'paddleGetCustomerId')
        const result = await callFn({email})
        return result.data
    }
}