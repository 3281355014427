import {Status, UserWord} from "$lib/classes/data/user-word";
import type {Interval, SentenceId, Timestamp, WordId} from "$lib/classes/data/_primitives";

export type UserWordJson = {
    id: WordId,
    status: Status,
    sentenceId: SentenceId | null,
    added: Timestamp,
    toLearnNext: Timestamp,
    interval: Interval,
}

export class UserWordConverter {
    fromJson(json: UserWordJson): UserWord {
        return new UserWord(
            json.id,
            json.status,
            json.sentenceId,
            json.added,
            json.toLearnNext,
            json.interval,
        );
    }

    toJson(userWord: UserWord): UserWordJson {
        return {
            id: userWord.id,
            status: userWord.status,
            sentenceId: userWord.sentenceId,
            added: userWord.added,
            toLearnNext: userWord.toLearnNext,
            interval: userWord.interval,
        }
    }
}