export function convertBetweenWordIdAndDbKey(keyOrId: string) {

    if (keyOrId.includes('/')) {
        return keyOrId.replace('/', '|')

    } else if (keyOrId.includes('|')) {
        return keyOrId.replace('|', '/')

    } else {
        return keyOrId
    }
}

