import {ASSETS_HOST} from "../../consts";
import type {WordConverter} from "$lib/classes/data/word-converter";
import type {SentenceConverter} from "$lib/classes/data/sentence-converter";
import type {NounJson, NumJson, WordClassJson} from "$lib/classes/data/word_zod";
import type {SentenceJson} from "$lib/classes/data/sentence_zod";
import type {Compressor} from "$lib/classes/services/compressor";

export async function loadWordsSentences(compressor: Compressor, wordConverter: WordConverter, sentenceConverter: SentenceConverter) {
    try {
        const response = await fetch(`${ASSETS_HOST}/ws-1.gzip`)
        if (!response.ok) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`HTTP error - fetch ws: ${response.status}, message: ${response.statusText}`);
        }
        const blob = await response.blob()
        const arrBuff = await blob.arrayBuffer()
        const [wordStr, sentenceStr] = compressor.inflate(arrBuff).split('$$$')

        console.time('parse native')
        const words = JSON.parse(wordStr).map((wordJson: WordClassJson | NounJson | NumJson) => wordConverter.fromJson(wordJson))
        const sentences = JSON.parse(sentenceStr).map((sentenceJson: SentenceJson) => sentenceConverter.fromJson(sentenceJson))
        console.timeEnd('parse native')

        return [words, sentences]
    } catch (error) {
        throw new Error('ERROR loadWordsSentences', {cause: error})
    }
}