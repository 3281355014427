import {writable} from "svelte/store";
import {logInProduction} from "$lib/utils/logInProduction";

export class RemoteChanges {
    private readonly store = writable(false)
    readonly subscribe = this.store.subscribe

    constructor(
        private onLogout: () => void,
    ) {}

    detected() {
        logInProduction('RemoteChanges detected')
        this.store.set(true)
        // QUESTION calling onLogout here appropriate?
        this.onLogout()
    }
}