<script lang="ts">
    import SvgIcon from '$lib/components/assets/SvgIcon.svelte';
</script>

<section>
    <h2>Where to start?</h2>
    <div id="home-dict-v-trainer">
        <div class="dict">
            <h2>
                <SvgIcon name="browse" width="50px" height="50px" viewBox="-4 -2 36 36"
                         fill="var(--color-Pronoun)"
                />
                Dictionary
            </h2>
            <p>
                Do you prefer to pick and choose the words you want to learn?
            </p>
            <a href="/browse">
                Browse »
            </a>
        </div>
        <div class="v-trainer">
            <h2>
                <SvgIcon name="learn" width="50px" height="50px" viewBox="0 0 32 32"
                         fill="var(--color-Verb)"
                />
                Vocab Trainer
            </h2>
            <p>
                Do you want to start learning right away by adding several words at once?
            </p>
            <a href="/learn">
                Learn »
            </a>
        </div>
    </div>
</section>

<style>
    section {
        margin-bottom: 9rem;
        text-align: center;
        /*border: 1px solid lightgrey;*/
    }

    section > h2 {
        margin: 0 0 7rem 0;
    }

    #home-dict-v-trainer {
        display: flex;
        max-width: min(100%, 44rem);
        margin: auto;
        /*border: 1px solid blue;*/
    }

    h2 {
        position: relative;
        margin: 0 0 1.6rem 0;
    }

    p {
        max-width: 30ch;
        margin: 0 0 2rem 0;
    }

    .dict h2 :global(svg),
    .v-trainer h2 :global(svg) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, calc(-100% - 1rem));
    }

    .dict,
    .v-trainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dict,
    .v-trainer {
        width: 100%;
    }

    a {
        display: block;
        margin: 0;
        padding: 1rem 2rem;
        border-radius: var(--border-radius);
        font-size: 1.15rem;
        font-weight: var(--font-bold);
        color: white;
    }

    .dict a {
        background: var(--color-Pronoun);
    }

    .v-trainer a {
        background: var(--color-Verb);
    }


    @media (max-width: 600px) {

        section {
            margin-bottom: 6rem;
        }

        section > h2 {
            margin: 0 0 5rem 0;
        }

        #home-dict-v-trainer {
            flex-direction: column;
            max-width: revert;
            margin-bottom: 2rem;
        }

        h2 {
            margin: 2rem 0 1.5rem 0;
        }

        p {
            font-size: 1rem;
        }

        .dict {
            margin-bottom: 5rem;
        }

    }

    @media (min-width: 600px) and (max-width: 1000px) {

        section {
            margin-bottom: 6rem;
        }

        #home-dict-v-trainer {
            flex-direction: row;
            max-width: min(100%, 44rem);
            margin-top: 8rem;
            margin-bottom: 2rem;
        }
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
        #home-dict-v-trainer {
            flex-direction: column;
            max-width: min(100%, 44rem);
            margin-bottom: 2rem;
        }

        .dict {
            margin: 0 0 8rem 0;
        }
    }

</style>