import {derived, type Readable} from 'svelte/store'
import type {ProVersion} from '$lib/classes/stores/pro-version';
import type {Subscription} from '$lib/classes/stores/subscription';
import type {ReviewCount} from '$lib/classes/stores/review-count';
import {REVIEW_COUNT_MAX} from '../../../consts';

export type FreeLimitReached = Readable<boolean>

export function getFreeLimitReached(
    loggedIn: Readable<boolean>,
    proVersion: ProVersion,
    subscription: Subscription,
    reviewCount: ReviewCount
) {
    return derived([loggedIn, proVersion, subscription, reviewCount],
        ([$loggedIn, $proVersion, $subscription, $reviewCount]) => {
            if ($loggedIn) {
                // TODO add isInTrialWeek
                // const isInTrialWeek = ...
                const hasActiveProVersion = $proVersion && $proVersion.active
                const hasActiveSubscription = $subscription !== null
                if (hasActiveProVersion || hasActiveSubscription) {
                    return false
                } else {
                    return $reviewCount >= REVIEW_COUNT_MAX
                }
            } else {
                return false
            }
        }
    )
}