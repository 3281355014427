import * as Sentry from '@sentry/sveltekit';
import {captureConsoleIntegration, extraErrorDataIntegration, replayIntegration} from '@sentry/sveltekit';
import {initMatomo} from '$lib/third-party/matomo.js';

const dev = import.meta.env.DEV
const disabledViaLocalStorage = localStorage.getItem('disableScripts') !== null
const enabledViaLocalStorage = localStorage.getItem('enableScripts') !== null

if ((dev || disabledViaLocalStorage) && !enabledViaLocalStorage) {
    console.warn('### HOOKS third-party scripts disabled via ' + (dev ? 'DEV MODE' : 'localStorage'))
} else {
    Sentry.init({
        dsn: 'https://aa3d8f8497c3d8bd9eb25ffabaecad26@monitor.vocabeo.com/2',
        tunnel: 'https://monitor.vocabeo.com/tunnel',
        tracesSampleRate: 1,
        replaysSessionSampleRate: 1,
        replaysOnErrorSampleRate: 1,
        integrations: [
            replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
            captureConsoleIntegration({levels: ['error']}),
            extraErrorDataIntegration(),
        ],
        ignoreErrors: [/^true$/],
    })
    initMatomo()
    // NOTE initialize Tawk in +layout.svelte since yOffset stopped working when run inside function from here...
}

export const handleError = async ({error, event, status, message}) => {
    // console.log('event >>>', event)
    // console.log('status >>>', status)
    // console.log('message >>>', message)

    // TODO what's the errorId about?
    const errorId = crypto.randomUUID();
    // console.log('handleError >>>', error.message)

    return {
        message: 'Something went wrong on our end. Please try again later or contact support if the problem persists.',
        errorId,
    };
};