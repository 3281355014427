import {ASSETS_HOST} from "../../consts.js";
import {timer} from "$lib/utils/timer.js";

export class VerbConjugations {

    value: Record<string, string> = {}

    constructor() { }

    async init() {
        const timer_verbConjugations = timer('verbConjugations.init')
        try {
            const response = await fetch(`${ASSETS_HOST}/vc.json`)
            if (response.ok) {
                this.value = await response.json()
            } else {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('reponse.status ' + response.status)
            }
        } catch (error) {
            throw new Error('ERROR VerbConjugations.init', {cause: error})
        }
        timer_verbConjugations()
    }
}