<!--suppress HtmlUnknownTag, CssInvalidHtmlTagReference -->
<script lang="ts">
    import {fade} from 'svelte/transition';
    import HorizontalRadioBtns from './Filter/HorizontalRadioBtns.svelte';
    import AllWordsUserWords from './Filter/AllWordsUserWords.svelte';
    import HorizontalRadioBtnsWordClass from './Filter/HorizontalRadioBtnsWordClass.svelte';
    import {onDestroy} from 'svelte';
    import Search from '$lib/components/browse/Filter/Search.svelte';
    import ResetFilterButton from '$lib/components/browse/Filter/ResetFilterButton.svelte';
    import type {FilterSettings} from '$lib/stores/filterSettings';
    import type {FilterSettingsFiltered} from '$lib/stores/filterSettings_filtered';
    import type {FilterOptions} from '$lib/data/filterOptions';
    import type {UserWordsMapStore} from '$lib/classes/stores/get-user-words-map';
    import type {DisplayWords} from '$lib/stores/displayWords';

    export let filterSettings: FilterSettings
    export let filterSettingsFiltered: FilterSettingsFiltered
    export let filterOptions: FilterOptions
    export let userWordsMap: UserWordsMapStore
    export let displayWords: DisplayWords

    onDestroy(() => {
        unsubFilterSettings()
        filterSettings.resetAll()
    })

    const unsubFilterSettings = filterSettings.subscribe(filterSettings => {
        displayWords.filter(filterSettings, $userWordsMap)
    });

</script>

<div id="filter-wrapper"
     in:fade|global={{duration: 400}}
     out:fade|global={{duration: 200}}
>

    <Search
            displayWords={displayWords}
            filterSettings={filterSettings}
            filtered={filterSettingsFiltered}
    />

    <HorizontalRadioBtns options={['alphabetically']} setName="Sort"
                         testId="sort-alphabetically"
                         bind:value={$filterSettings.sortAlph}
                         firstValue="by frequency"
                         fontSize=".9rem"
                         maxHeight="1.7rem"
    />

    <AllWordsUserWords
            filterSettings={filterSettings}
            bind:value={$filterSettings.words}
    />

    <HorizontalRadioBtnsWordClass options={filterOptions.art} setName="Word class" bind:value={$filterSettings.art}/>

    <HorizontalRadioBtns options={filterOptions.level.concat('-')} setName="Level" bind:value={$filterSettings.level}
                         testId="filter-for-level"
    >
        <!--        <Info/>-->
    </HorizontalRadioBtns>

    <!--    <HorizontalRadioBtns options={$filterOptions.frequency} setName="Frequency" bind:value={$filterSettings.frequency}/>-->

    <!--    <HorizontalRadioBtns options={$filterOptions.syllables} setName="Syllables" bind:value={$filterSettings.syllables}/>-->

    <ResetFilterButton
            filterSettings={filterSettings}
            filtered={filterSettingsFiltered}
    />

</div>

<style>

    #filter-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        /*because of Goethe popup*/
        /*overflow: visible;*/
    }

</style>