<!--suppress CssUnresolvedCustomProperty -->
<script lang="ts">
    import SvgIcon from '$lib/components/assets/SvgIcon.svelte';
    import {page} from '$app/stores';
    import {fade} from 'svelte/transition'
    import type {Readable} from 'svelte/store';

    export let loggedIn: Readable<boolean>
</script>

<nav aria-labelledby="primary-navigation">
    <ul>
        <li id="nav-link-browse">
            <a href="/browse">
                <div class="left browse-link">
                    <SvgIcon name="browse" width="28px" height="28px" viewBox="-4 -2 36 36"
                             fill="white"
                    />
                </div>
                <div class="right"
                     class:active={ $page.url.pathname.startsWith('/browse') }
                >
                    Browse
                    <span class="arrow">&nbsp;»</span>
                </div>
            </a>
        </li>

        <li id="nav-link-learn">
            <a href="/learn">
                <div class="left learn-link">
                    <SvgIcon name="learn" width="28px" height="28px" viewBox="0 0 32 32"
                             fill="white"
                    />
                </div>
                <div class="right"
                     class:active={ $page.url.pathname.startsWith('/learn') }
                >
                    Learn
                    <span class="arrow">&nbsp;»</span>
                </div>
            </a>
        </li>

        {#if $loggedIn}
            <li id="nav-link-stats" transition:fade|global>
                <a href="/stats">
                    <div class="left stats-link">
                        <SvgIcon name="stats" width="28px" height="28px" viewBox="-2 0 35 35"
                                 fill="white"
                        />
                    </div>
                    <div class="right"
                         class:active={ $page.url.pathname.startsWith('/stats') }
                    >
                        Stats
                        <span class="arrow">&nbsp;»</span>
                    </div>
                </a>
            </li>
        {/if}
    </ul>
</nav>


<style>
    nav {
        grid-area: nav;
        z-index: var(--z-index-nav-links);
    }

    ul {
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;

        display: flex;
        flex-direction: column;
    }

    li {
        user-select: none;
        -webkit-user-select: none;
        border-radius: var(--border-radius);
    }

    li + li {
        margin-top: .5rem;
    }

    #nav-link-browse {
        grid-area: browse;
    }

    #nav-link-learn {
        grid-area: learn;
    }

    #nav-link-stats {
        grid-area: stats;
    }

    a {
        display: flex;
        color: inherit;
        text-decoration: none;
        font-weight: var(--font-emph);
    }

    .left,
    .right {
        display: flex;
        align-items: center;
        padding: var(--margin-medium);
        border: 2px solid var(--color-border);
        transition: all 200ms;
    }

    .right {
        width: 100%;
        padding-left: .8em;
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
        border-left: none;
    }

    .browse-link,
    .learn-link,
    .stats-link {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    .browse-link {
        background: var(--color-accent);
        border-color: var(--color-accent);
    }

    .learn-link {
        background: var(--color-Verb);
        border-color: var(--color-Verb);
    }

    .stats-link {
        background: var(--color-Noun);
        border-color: var(--color-Noun);
    }

    #nav-link-browse:hover .right,
    #nav-link-browse .right.active {
        border-color: var(--color-accent);
    }

    #nav-link-learn:hover .right,
    #nav-link-learn .right.active {
        border-color: var(--color-Verb);
    }

    #nav-link-stats:hover .right,
    #nav-link-stats .right.active {
        border-color: var(--color-Noun);
    }

    /*Class needed when a word do learn is added to deck*/
    /*noinspection CssUnusedSymbol*/
    :global(.emph-learn),
    :global(.emph-learn) .left,
    :global(.emph-learn) .right {
        box-shadow: 0 0 0 1px var(--color-Verb);
        border-color: var(--color-Verb);
    }

    @media (prefers-color-scheme: dark) {
        .left :global(svg) {
            fill: var(--text-on-color);
        }

        .right {
            background: var(--color-dark-2);
        }
    }

    @media (max-width: 1000px) {
        ul {
            height: 100%;
            flex-direction: row;
            align-items: center;
            padding: 0 .6rem;
            border-top: 1px solid var(--color-dark);
            /*background: var(--color-light);*/
        }

        li {
            width: 100%;
            font-size: .8rem;
        }

        li + li {
            margin: 0 0 0 .6rem;
        }

        .right {
            padding: 0;
            justify-content: center;
            width: 100%;
        }

        .arrow {
            display: none;
        }
    }
</style>