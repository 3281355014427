import {derived, type Readable} from 'svelte/store'
import {type FilterSettings, type FilterSettingsValue} from '$lib/stores/filterSettings';

export type FilterSettingsFiltered = Readable<boolean>

export function getFilterSettingsFiltered(filterSettings: FilterSettings) {
    return derived(filterSettings,
        $filterSettings => {
            const excludedKeys: (keyof FilterSettingsValue)[] = ['search', 'sorting']
            return !Object.entries($filterSettings)
                .filter(([key, _]) => !excludedKeys.includes(key as keyof FilterSettingsValue))
                .every(([_, prop]) => prop === '')
        }
    )
}