import {
    Adjective, Adverb, Article, Conjunction, Interjection,
    Noun, genus, Num, Preposition, Pronoun, Verb,
    Word,
    WordBase, WordClass
} from "$lib/classes/data/word";
import type {NounJson, NumJson, WordBaseJson, WordClassJson} from "$lib/classes/data/word_zod";


export class WordConverter {
    fromJson(wordJson: WordClassJson | NounJson | NumJson): Word {
        const wordBase = new WordBase(
            wordJson.base.displayWord,
            wordJson.base.syllables,
            wordJson.base.phonetic,
            wordJson.base.frequency,
            wordJson.base.rank,
            wordJson.base.level,
            wordJson.base.translations,
            wordJson.base.imgNr,
        )
        switch (wordJson['@type']) {
            case 'Adjective':
                return new Adjective(wordBase);
            case "Adverb":
                return new Adverb(wordBase);
            case 'Article':
                return new Article(wordBase);
            case 'Conjunction':
                return new Conjunction(wordBase);
            case 'Interjection':
                return new Interjection(wordBase);
            case 'Noun':
                return new Noun(wordBase, genus(wordJson.genus), wordJson.plural, wordJson.flexions);
            case 'Num':
                return new Num(wordBase, wordJson.num);
            case 'Preposition':
                return new Preposition(wordBase);
            case 'Pronoun':
                return new Pronoun(wordBase);
            case 'Verb':
                return new Verb(wordBase);
            default:
                throw new Error('WordConverter unknown @type')
        }
    }

    toJson(word: Word): WordClassJson | NounJson | NumJson {

        const wordBaseJson: WordBaseJson = {
            displayWord: word.base.displayWord,
            syllables: word.base.syllables,
            phonetic: word.base.phonetic,
            frequency: word.base.frequency,
            rank: word.base.rank,
            level: word.base.level,
            translations: word.base.translations,
            imgNr: word.base.imgNr,
        }

        switch (true) {
            case word instanceof Adjective:
                return {base: wordBaseJson, '@type': WordClass.Adjective};
            case word instanceof Adverb:
                return {base: wordBaseJson, '@type': WordClass.Adverb};
            case word instanceof Article:
                return {base: wordBaseJson, '@type': WordClass.Article};
            case word instanceof Conjunction:
                return {base: wordBaseJson, '@type': WordClass.Conjunction};
            case word instanceof Interjection:
                return {base: wordBaseJson, '@type': WordClass.Interjection};
            case word instanceof Noun:
                return {
                    base: wordBaseJson,
                    genus: word.genus.toString(),
                    plural: word.plural,
                    flexions: word.flexions,
                    '@type': WordClass.Noun,
                };
            case word instanceof Num:
                return {base: wordBaseJson, num: word.num, '@type': WordClass.Num};
            case word instanceof Preposition:
                return {base: wordBaseJson, '@type': WordClass.Preposition};
            case word instanceof Pronoun:
                return {base: wordBaseJson, '@type': WordClass.Pronoun};
            case word instanceof Verb:
                return {base: wordBaseJson, '@type': WordClass.Verb};
            default:
                throw new Error('WordConverter unknown class instance')
        }
    }
}