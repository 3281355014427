import type {Database} from '$lib/classes/services/database';
import {type Writable, writable} from 'svelte/store';
import {add, differenceInCalendarDays, format} from 'date-fns';
import type {User} from 'firebase/auth';
import {timer} from '$lib/utils/timer';
import {formatDate} from '$lib/helper-fns/formatDate';

export type ProVersionDocDB = {
    until: string
    orderId: string | null
}

export type ProVersionDocLocal = ProVersionDocDB & {
    active: boolean,
    untilDateString: string
}

export class ProVersion {
    private readonly store: Writable<ProVersionDocLocal | null> = writable(null)
    readonly subscribe = this.store.subscribe

    private cancelDocSubscription = () => {}
    private interval: ReturnType<typeof setInterval> | undefined = undefined

    constructor(
        private readonly database: Database
    ) {
    }

    private calculateActive(until: string) {
        return differenceInCalendarDays(new Date(), new Date(until + 'T00:00:00')) <= 0
    }

    private updateActive() {
        this.store.update((proVersionDoc) => {
            if (proVersionDoc === null) {
                return proVersionDoc
            } else {
                proVersionDoc.active = this.calculateActive(proVersionDoc.until)
                return proVersionDoc
            }
        })
    }

    async writeInitialDoc(user: User) {
        const creationTime = user.metadata.creationTime
        if (!creationTime) {
            throw new Error('ERROR proVersion.writeInitialDoc - no creationTime')
        }
        const createdAt = new Date(user.metadata.creationTime)
        const until = format(add(createdAt, {weeks: 1}), 'yyyy-MM-dd')
        const proVersionDoc: ProVersionDocDB = {
            orderId: null,
            until
        }
        await this.database.setProVersionDoc(user.uid, proVersionDoc)
    }

    init(user: User): Promise<void> {
        const timer_proVersion = timer('proVersion.init')

        return new Promise(async (resolve, reject) => {
            try {
                const proVersionDoc = await this.database.getProVersionDoc(user.uid)
                if (proVersionDoc === null) {
                    await this.writeInitialDoc(user)
                }
            } catch (error) {
                reject(new Error('ERROR proVersion.init - check existing proVersionDoc', {cause: error}))
            }

            try {
                this.cancelDocSubscription = await this.database.subscribeProVersionDoc(
                    user.uid,
                    (doc: ProVersionDocDB | null) => {
                        if (doc) {
                            const proVersionDocLocal: ProVersionDocLocal = {
                                ...doc,
                                active: this.calculateActive(doc.until),
                                untilDateString: formatDate(new Date(doc.until + 'T00:00:00'))
                            }
                            this.store.set(proVersionDocLocal)
                        } else {
                            this.store.set(null)
                        }
                        timer_proVersion()
                        resolve()
                    }
                )
            } catch (error) {
                reject(new Error('ERROR proVersion.init - subscribeProVersionDoc', {cause: error}))
            }

            const proVersionInstance = this
            this.interval = setInterval(this.updateActive.bind(proVersionInstance), 60000)
        })
    }

    close() {
        this.cancelDocSubscription()
        clearInterval(this.interval)
        this.store.set(null)
    }
}