import {writable} from 'svelte/store';
import {Filter} from "../utils/filter";
import type {Word} from "$lib/classes/data/word";
import type {FilterSettingsValue} from '$lib/stores/filterSettings';
import type {UserWordsMap} from "$lib/classes/stores/get-user-words-map";

export type DisplayWords = ReturnType<typeof getDisplayWords>

export function getDisplayWords() {
    let initValue: Word[] = []
    const {subscribe, set} = writable(initValue)

    let allWords: Word[] = []
    return {
        subscribe,
        set,
        init(startValue: Word[]) {
            allWords = startValue
            set(startValue)
        },
        filter(filterSettings: FilterSettingsValue, userWordsMap: UserWordsMap) {
            // spreaded Array wichtig, damit sort nicht $allWords ändert!
            let words = [...allWords]

            // All, UserWords, KNOWN or LEARN
            words = Filter.filterUserWords(words, userWordsMap, filterSettings.words)

            words = Filter.filterSearchTerm(words, filterSettings.search)

            words = Filter.filterStaticOptions(words, filterSettings);

            // markFirstEnglish - last step!
            if (filterSettings.search) words = Filter.markFirstEnglishWord(words)

            set(words)
        }
    }
}