<!--suppress HtmlUnknownAttribute -->
<script lang="ts">
    export let options: string[]
    export let setName: string
    export let firstValue = 'All'
    export let value = ''
    export let fontSize = '.95rem'
    export let maxHeight = '2rem'
    export let testId: string
</script>

<div class="setName">
    {setName}
    <slot></slot>
</div>

<div class="horizontal-radio-btns"
     data-testid={testId}
     style:max-height={maxHeight}
>
    <label class="first-label"
           for={setName}
           class:selected={value === ''}
           style:font-size={fontSize}
    >
        {firstValue}
        <input type="radio" id={setName} name={setName} value="" bind:group={value}>
    </label>

    {#each options as option, index}
        <label for={`${setName}-${index}`}
               class:selected={value === option}
               style:font-size={fontSize}
        >
            {option}
            <input type="radio"
                   id={`${setName}-${index}`}
                   name="{setName}"
                   value={option}
                   bind:group={value}>
        </label>
    {/each}

</div>

<style>
    input:focus-visible {
        outline: none;
    }

    .setName {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: var(--margin-big);
        margin-bottom: var(--margin-medium);
        font-size: .9rem;
        font-weight: var(--font-emph);
    }

    .horizontal-radio-btns {
        height: 100%;
        min-height: 1.6rem;
        display: flex;
        flex-wrap: wrap;
    }

    label:first-of-type {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    label:last-of-type {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
        border-right: 0;
    }

    input {
        position: absolute;
        margin: 0;
        z-index: -1;
    }

    label {
        flex-grow: 1;
        background: var(--color-light);
        transition: background 200ms, border 200ms;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        border-right: 2px solid var(--color-dark);
    }

    .selected {
        font-weight: var(--font-emph);
        color: var(--color-light);
        background: var(--color-accent);
        border-color: var(--color-accent);
    }

    @media (prefers-color-scheme: dark) {
        .selected {
            color: var(--color-dark-2);
        }
    }

</style>