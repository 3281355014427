import type {WordId} from "$lib/classes/data/_primitives";
import type {Word} from "$lib/classes/data/word";

// TODO refactor filename !!

export type WordIdToWord = Record<WordId, Word>

export let wordIdToWord: WordIdToWord = {}

export function initWordIdToWord(words: Word[]) {
    // wordIdToWord = words.reduce((map, word) => {
    //     map[word.id] = word
    //     return map
    // }, {})
    words.forEach(word => {
        wordIdToWord[word.id] = word
    })
}