<script lang="ts">
    export let text: string
    export let id: number
    export let long: boolean
    export let onChange: () => void

    let checked = false
</script>

<div class="text-wrapper">
    <div class="text"
         class:long={long}
         class:full-height={checked}
    >
        {text}
    </div>
    {#if long}
        <label for="read-more-toggle-{id}">
            {checked ? 'Read Less' : 'Read More'}
            <input
                    type="checkbox"
                    id="read-more-toggle-{id}"
                    bind:checked
                    on:change={onChange}
            />
        </label>
    {/if}
</div>

<style>
    .text-wrapper {
        position: relative;
        line-height: 1.5;
    }

    .text.long {
        display: -webkit-box;
        max-height: 10lh;
        line-clamp: 10;
        -webkit-line-clamp: 10; /* Number of lines you want to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @media (max-width: 1499px) {
        .text.long {
            max-height: 7lh;
            line-clamp: 7;
            -webkit-line-clamp: 7; /* Number of lines you want to show */
        }
    }

    @media (max-width: 1220px) {
        .text.long {
            max-height: 5lh;
            line-clamp: 5;
            -webkit-line-clamp: 5; /* Number of lines you want to show */
        }
    }

    /* overwrite with container query when available */
    @container (min-width: 800px) {
        .text.long {
            max-height: 10lh;
            line-clamp: 10;
            -webkit-line-clamp: 10; /* Number of lines you want to show */
        }
    }

    @container (max-width: 799px) {
        .text.long {
            max-height: 7lh;
            line-clamp: 7;
            -webkit-line-clamp: 7; /* Number of lines you want to show */
        }
    }

    @container (max-width: 500px) {
        .text.long {
            max-height: 5lh;
            line-clamp: 5;
            -webkit-line-clamp: 5; /* Number of lines you want to show */
        }
    }

    input {
        position: absolute;
        opacity: 0;
    }

    label {
        display: block;
        margin-top: .2rem;
        color: var(--color-darker);
        user-select: none;
        cursor: pointer;
    }

    .text.full-height {
        display: revert;
        max-height: revert;
    }

    /* TODO replace text and class based on 'checked' via js with :has() when even wider supported */

    /*label::before {*/
    /*    color: var(--color-darker);*/
    /*}*/

    /*label:has(input:not(:checked))::before {*/
    /*    content: 'Read more';*/
    /*}*/

    /*label:has(input:checked)::before {*/
    /*    content: 'Read less';*/
    /*}*/

    /*.text-wrapper:has(input:checked) .text {*/
    /*    display: revert;*/
    /*    max-height: revert;*/
    /*}*/

</style>