export function initMatomo() {
    // @ts-ignore
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['setCustomDimension', 1, getTheme()]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    _paq.push(['enableHeartBeatTimer', 5]); // track time spent on site

    (function () {
        var u = "https://corinnasteinhoff.de/matomo/";
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '1']);
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = u + 'matomo.js';
        s.parentNode?.insertBefore(g, s);
    })();
}

function getTheme() {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const prefersLightScheme = window.matchMedia("(prefers-color-scheme: light)").matches;

    return prefersDarkScheme ? 'dark' : prefersLightScheme ? 'light' : 'unknown'
}

// ORIGINAL BLOCK FROM GUIDE
//     var _paq = window._paq = window._paq || [];
//     /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
//     _paq.push(['trackPageView']);
//     _paq.push(['enableLinkTracking']);
//     (function() {
//     var u="https://corinnasteinhoff.de/matomo/";
//     _paq.push(['setTrackerUrl', u+'matomo.php']);
//     _paq.push(['setSiteId', '1']);
//     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//     g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
// })();
