<script lang="ts">

</script>

<section>

    <h2>
        Is vocabeo enough?
    </h2>

    <p class="higher">
        To become proficient in German, you need to develop your skills in various areas, including writing, speaking,
        reading, listening, and vocabulary. Each of these areas requires a different approach and set of resources. <b>vocabeo</b>
        covers the area of efficient vocabulary acquisition. For the other fields a collection of useful links can be
        found here
    </p>

    <a href="/resources">
        » Resources for learning German
    </a>

</section>

<style>
    section {
        display: flex;
        flex-direction: column;
        margin-top: 6rem;
    }

    p {
        margin: 0 0 2.2rem 0;
    }

    a {
        width: fit-content;
        padding: 1rem 1.4rem;
        color: white;
        background: var(--color-Adverb);
        font-weight: var(--font-bold);
        border-radius: var(--border-radius);
    }

    @media (max-width: 400px) {
        a {
            font-size: .94rem;
            padding: .7rem .7rem;
        }
    }
</style>