<!--suppress CssUnusedSymbol -->
<script lang="ts">
    import type {FilterSettings} from '$lib/stores/filterSettings';
    import type {FilterSettingsFiltered} from '$lib/stores/filterSettings_filtered';
    import type {DisplayWords} from '$lib/stores/displayWords';

    export let filterSettings: FilterSettings
    export let filtered: FilterSettingsFiltered
    export let displayWords: DisplayWords

    let searchInput: HTMLInputElement;

    function resetSearch() {
        filterSettings.resetSearch()
        searchInput.focus()
    }

    function handleBeforeInput(event: InputEvent) {
        const data = event.data // new content
        if (data === '1' || data === '2') {
            event.preventDefault()
        }
    }

    function handleInput() {
        if ($filtered) filterSettings.resetWordsAndStatic()
    }
</script>

<div id="search">
    <div id="search-word-count">
        <label for="filter-search">Search</label>
        <div id="display-words-count"
             data-testid="display-words-count"
        >
            <div id="display-words-count-number">
                {$displayWords.length.toLocaleString()}
            </div>
            <div id="display-words-count-label">
                words
            </div>
        </div>
    </div>

    <div id="search-wrapper">
        <input id="filter-search" type="text"
               class:search-active={$filterSettings.search}
               bind:value={$filterSettings.search}
               bind:this={searchInput}
               on:keydown={(event) => {if(event.code === 'Escape') resetSearch()}}
               on:input={handleInput}
               on:beforeinput={handleBeforeInput}
               autocomplete="off">
        <!-- svelte-ignore a11y-click-events-have-key-events  a11y-no-static-element-interactions -->
        <div id="reset-search" class:invisible={!$filterSettings.search} on:click={resetSearch}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2 -2 26 26">
                <title>reset search</title>
                <path d="M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z"/>
                <path d="M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z"/>
            </svg>
        </div>
    </div>
</div>

<style>
    #search-word-count {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: .9rem;
        font-weight: var(--font-emph);
        margin-bottom: var(--margin-medium);
        margin-top: 10px;
        /*border: 1px solid purple;*/
    }

    #display-words-count {
        padding-right: 2px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    #display-words-count-label {
        font-weight: normal;
        font-size: .8rem;
        color: var(--color-accent);
    }

    #display-words-count-number {
        font-size: 1.15rem;
        color: var(--color-accent);
        filter: brightness(.85);
        font-weight: var(--font-bold);
    }

    #search-wrapper {
        position: relative;
    }

    input[type="text"] {
        width: 100%;
        margin: 0;
        padding: 5px 0 5px .4rem;
        border: 2px solid var(--color-medium);
        border-radius: var(--border-radius);
    }

    input[type="text"]:global(.search-active) {
        border: 2px solid var(--color-accent);
        /*to make box-shadow visible on iOs*/
        appearance: none;
        -webkit-appearance: none;
        box-shadow: 0 0 0 4px rgba(202, 139, 22, .5);
    }

    input[type='text']:focus {
        outline: 0;
        border: 2px solid var(--color-accent);
        /*box-shadow: 0 2px 0 0 black;*/
    }

    #reset-search {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        top: 0;
        right: 0;
        padding: 0 4px;
        cursor: pointer;
        /*user-select: none;*/
    }

    #reset-search svg {
        fill: var(--color-main);
    }


    @media (max-width: 1000px) {

        #search-word-count {
            margin: 0;
        }

        #display-words-count {
            display: none;
        }

        input[type="text"]:global(.search-active) {
            border: 2px solid var(--color-accent);
            /*to make box-shadow visible on iOs*/
            appearance: none;
            -webkit-appearance: none;
            box-shadow: 0 0 0 2px rgba(202, 139, 22, .5);
        }

        label {
            font-size: .8rem;
            margin-left: 2px;
            margin-bottom: 2px;
        }
    }
</style>