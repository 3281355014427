import type {Interval as IntervalTimestamp} from '$lib/classes/data/_primitives'

// TODO type rating !!

export const Interval = {

    modifier: {
        hard: 0.8,
        good: 1.6,
        easy: 2.4
    },

    calculateNewInterval(currentInterval: IntervalTimestamp, rating: 'hard' | 'good' | 'easy') {

        let newInterval = Math.trunc(currentInterval * this.modifier[rating])

        // minimal interval => 24h = 86400000
        return newInterval < 86400000 ? 86400000 : newInterval
    },

    calculateToLearnNext(interval: IntervalTimestamp) {
        return Date.now() + interval
    }
}
