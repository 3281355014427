import {type Writable, writable} from "svelte/store";
import type {Word} from "$lib/classes/data/word";

export type CurrentWord = ReturnType<typeof getCurrentWord>

export function getCurrentWord() {
    const store: Writable<Word | null> = writable(null)

    return {
        subscribe: store.subscribe,
        setCurrentWord(word: Word) {
            store.set(word)
        },
    }
}