import type {Database} from '$lib/classes/services/database';
import type {UserWordConverter, UserWordJson} from '$lib/classes/data/user-word-converter';
import type {RemoteChanges} from '$lib/classes/stores/remote-changes';
import type {WordIdToWord} from '$lib/data/wordIdToWord';
import type {WordIdToSentences} from '$lib/data/wordIdToSentences';
import type {WordIdChanges} from '$lib/data/word-id-changes';
import type {Interval} from '$lib/utils/userWords/interval';
import type {SentenceId, UserId, WordId} from '$lib/classes/data/_primitives';
import {Status, UserWord} from '$lib/classes/data/user-word';
import {Action, type UserWordChange} from '$lib/classes/data/user-word-change';
import {get, writable} from 'svelte/store';
import {validateUserWordChanges} from '$lib/utils/userWords/validate-user-word-changes';
import {logInProduction} from '$lib/utils/logInProduction';
import {timer} from '$lib/utils/timer';

export enum Rating {
    Repeat = 'repeat',
    Known = 'known',
    Hard = 'hard',
    Good = 'good',
    Easy = 'easy'
}

export type UserWordsMap = Record<WordId, UserWord>

export type UserWordsMapStore = ReturnType<typeof getUserWordsMap>

export function getUserWordsMap(
    database: Database,
    userWordConverter: UserWordConverter,
    remoteChanges: RemoteChanges,
    wordIdToWord: WordIdToWord,
    wordIdToSentences: WordIdToSentences,
    wordIdChanges: WordIdChanges,
    interval: typeof Interval,
) {
    // TODO change to just setting to new object {}
    const initialValue: UserWordsMap = {}
    const {subscribe, set, update} = writable({...initialValue})

    function reset() {
        set({...initialValue})
    }

    function create(id: WordId, status: Status, sentenceId: SentenceId | null) {
        const now = Date.now()
        return new UserWord(id, status, sentenceId, now, now)
    }

    function add(userWord: UserWord) {
        update(value => {
            value[userWord.id] = userWord
            return value
        })
    }

    function remove(userWord: UserWord) {
        update(value => {
            delete value[userWord.id]
            return value
        })
    }

    let uid: UserId | null = null

    async function saveUserWord(userWord: UserWord) {
        if (uid) {
            try {
                await database.setUserWordsDoc(uid, userWord)
            } catch (error) {
                console.error('error while saving UserWord to DB', error)
            }
        } else {
            add(userWord)
        }
    }

    async function deleteUserWord(userWord: UserWord) {
        if (uid) {
            try {
                await database.deleteUserWord(uid, userWord)
            } catch (error) {
                console.error('error while deleting UserWord from DB', error)
            }
        } else {
            remove(userWord)
        }
    }

    let initialSnapshot = true

    async function handleUserWordChanges(changes: UserWordChange[]) {

        // console.log('initial', initialSnapshot, 'changes', changes)
        if (initialSnapshot) {
            const {
                validated,
                toSave,
                toDelete
            } = validateUserWordChanges(changes, wordIdToWord, wordIdToSentences, wordIdChanges)
            if (toSave.length > 0 || toDelete.length > 0) console.log('>>>', 'validated', validated, 'toSave', toSave, 'toDelete', toDelete)

            validated.forEach(userWord => add(userWord))
            toSave.forEach(userWord => saveUserWord(userWord))
            toDelete.forEach(userWord => deleteUserWord(userWord))

            initialSnapshot = false
        } else {
            changes.forEach(change => {

                const action = change.action

                if (!change.changedLocally && action !== Action.Removed) {
                    // changes from remote client >> force reload !
                    remoteChanges.detected()
                    return
                }

                const userWord = change.userWord

                if (action === Action.Added) {
                    // console.log('added', userWord)
                    add(userWord)
                }
                if (action === Action.Modified) {
                    // console.log("modified", userWord);
                    add(userWord)
                }
                if (action === Action.Removed) {
                    // console.log("removed", userWord);
                    remove(userWord)
                }
            })
        }
    }

    return {
        subscribe,
        // NOTE will become private fields on class
        LOCAL_STORAGE_KEY: 'user-words-v1',
        savePersistedUserWords: false,
        cancelCollectionSubscription: () => {},
        cancelPersistenceSubscription: () => {},

        async init(userId: UserId) {
            const timer_userWordsMap = timer('userWordsMap.init')
            this.unpersist()
            uid = userId
            reset() //important!!! Words may have been added before when logged out
            try {
                const userWordsMapInstance = this
                this.cancelCollectionSubscription = await database.subscribeUserWordsCollection(userId, handleUserWordChanges.bind(userWordsMapInstance))
            } catch (error) {
                throw new Error('ERROR userWordsMap.init', {cause: error})
            }
            timer_userWordsMap()
        },

        persist() {
            this.cancelPersistenceSubscription = this.subscribe(userWordsMap => {
                // TODO LocalStorageManager
                const userWords = Object.values(userWordsMap)
                const userWordsJson = userWords.map(userWord => userWordConverter.toJson(userWord))
                try {
                    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(userWordsJson))
                } catch (error) {
                    console.error('ERROR persisting userWords to localStorage', error)
                }
            })
        },

        unpersist() {
            this.cancelPersistenceSubscription()
            //NOTE don't remove local storage entry - needed for loadAndSavePersistedUserWords
        },

        activateSavingPersistedUserWords() {
            this.savePersistedUserWords = true
        },

        async loadAndSavePersistedUserWords(userId: UserId) {
            if (!this.savePersistedUserWords) return
            uid = userId
            const userWordsStr = localStorage.getItem(this.LOCAL_STORAGE_KEY)
            if (userWordsStr) {
                const promises = (JSON.parse(userWordsStr) as UserWordJson[]).map(userWordJson => {
                    const userWord = userWordConverter.fromJson(userWordJson)
                    saveUserWord(userWord)
                })
                const results = await Promise.allSettled(promises)
                const rejectedResults = results.filter(result => result.status === 'rejected')
                if (rejectedResults.length > 0) {
                    console.error('ERROR saving persisted userWords to DB', rejectedResults.map(r => r.reason).join('\n'))
                }
                localStorage.removeItem(this.LOCAL_STORAGE_KEY)
            }
        },

        close() {
            this.cancelCollectionSubscription()
            reset()
            uid = null
            initialSnapshot = true
            this.persist()
        },

        statusChangeRequest(id: WordId, newStatus: Status | null) {

            const userWordsMap = get(this)
            let userWord = userWordsMap[id]
            const currentStatus = userWord?.status.toString()

            const noChange = currentStatus === newStatus
            if (noChange) return

            const addUserWord = !userWord && newStatus !== null
            const toggleStatus = (currentStatus === 'KNOWN' && newStatus === 'LEARN') || (currentStatus === 'LEARN' && newStatus === 'KNOWN')
            const deleteThisUserWord = !newStatus

            if (addUserWord) {
                logInProduction('add ' + id + ' - ' + new Date().toLocaleString() + ' - ' + Date.now())

                const sentenceId = wordIdToSentences[id]?.[0]?.id ?? null
                userWord = create(id, newStatus, sentenceId)

                console.log('addUserWord', userWord)
                saveUserWord(userWord)

            } else if (toggleStatus) {
                logInProduction('toggle ' + id + ' - ' + new Date().toLocaleString() + ' - ' + Date.now())

                userWord.toggleStatus()
                saveUserWord(userWord)

            } else if (deleteThisUserWord) {
                logInProduction('delete ' + id + ' - ' + new Date().toLocaleString() + ' - ' + Date.now())

                deleteUserWord(userWord)
            }
        },

        sentenceChangeRequest(id: WordId, newSentenceId: SentenceId) {

            const userWordsMap = get(this)
            let userWord = userWordsMap[id]

            const currentSentenceId = userWord?.sentenceId

            const sameSentence = newSentenceId === currentSentenceId
            if (sameSentence) return

            const changeSentenceId = userWord && newSentenceId !== currentSentenceId
            const addUserWord = !userWord

            if (changeSentenceId) {
                userWord.sentenceId = newSentenceId
            } else if (addUserWord) {
                userWord = create(id, Status.Learn, newSentenceId)
            }

            saveUserWord(userWord)
        },

        rate(userWord: UserWord, rating: Exclude<Rating, Rating.Repeat>) {

            if (rating === Rating.Known) {
                console.log('rating >> known')
                this.statusChangeRequest(userWord.id, Status.Known)

            } else {
                // rating === 'hard', 'good', 'easy'

                const currentInterval = userWord.interval

                const newInterval = interval.calculateNewInterval(currentInterval, rating)
                const toLearnNext = interval.calculateToLearnNext(newInterval)
                console.log('rating', rating.toUpperCase(), 'current', currentInterval, '*', interval.modifier[rating], 'newInterval', newInterval, 'toLearnNext', new Date(toLearnNext).toLocaleString())

                userWord.interval = newInterval
                userWord.toLearnNext = toLearnNext

                // DEVELOPMENT
                // dev: userWord.toLearnNext = Date.now()

                saveUserWord(userWord)
            }
        }
    }
}