<script lang="ts">
    import Footer from '$lib/components/_page/Footer.svelte';
    import {afterNavigate} from '$app/navigation';
    import {getContext} from 'svelte';
    import {keyDevice} from '$lib/context-keys';
    import {type Device, DeviceType} from '$lib/stores/ui/device';

    const device: Device = getContext(keyDevice)

    export let footer = false
    export let pageMaxWidth = 1200

    let pageWrapper: HTMLDivElement

    afterNavigate(() => pageWrapper.scrollTop = 0)
</script>

<div id="page-wrapper"
     data-testid="page-wrapper"
     bind:this={pageWrapper}
     class:styled-scrollbars={$device === DeviceType.Desktop}
     style="--page-max-width: {pageMaxWidth}px;"
>
    <div id="page-slot-wrapper">
        <slot/>
    </div>

    <div id="footer-wrapper">
        {#if footer}
            <Footer/>
        {/if}
    </div>

</div>

<style>
    #page-wrapper {
        display: grid;
        justify-items: center;
        grid-template-rows: 1fr max-content 3fr;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: var(--color-light);
        border-left: 2px solid var(--color-dark);

        --page-padding-inline: 6rem;
        --page-padding-block: 4rem;
    }

    #page-slot-wrapper {
        grid-row: 2;
        width: 100%;
        /*noinspection CssUnresolvedCustomProperty*/
        max-width: var(--page-max-width);
        padding: var(--page-padding-block) var(--page-padding-inline);
    }

    #footer-wrapper {
        align-self: flex-end;
        grid-row: 3;
        width: 100%;
        /*noinspection CssUnresolvedCustomProperty*/
        max-width: var(--page-max-width);
    }

    @media (prefers-color-scheme: dark) {

        #page-wrapper {
            background: var(--color-dark-2);
        }

    }

    @media (max-width: 1000px) {
        #page-wrapper {
            --page-padding-inline: 1.5rem;
            --page-padding-block: 2rem;
            border: revert;
        }

        #page-slot-wrapper {
            border: revert;
        }
    }
</style>