import {type Sentence} from "$lib/classes/data/sentence";
import type {WordId} from "$lib/classes/data/_primitives";

// TODO make static method on Sentence
export function sortByIndex(sentences: Sentence[], id: WordId) {
    return sentences.sort((s1, s2) => {
            const wL1 = s1.wordLinksWithId(id)[0]
        const i1 = wL1.i
            const wL2 = s2.wordLinksWithId(id)[0]
        const i2 = wL2.i

        if (i1 === null && i2 !== null) {
                return 1
        } else if (i1 !== null && i2 === null) {
                return -1
        } else if (i1 === null && i2 === null) {
            return 0
            } else {
            // @ts-ignore
                return i1 - i2
            }
        }
    )
}