import type {Frequency, WordId} from '$lib/classes/data/_primitives';

export enum WordClass {
    Adjective = 'Adjective',
    Adverb = 'Adverb',
    Article = 'Article',
    Conjunction = 'Conjunction',
    Interjection = 'Interjection',
    Noun = 'Noun',
    Num = 'Num',
    Preposition = 'Preposition',
    Pronoun = 'Pronoun',
    Verb = 'Verb',
}

export type Syllable = {
    syllable: string
}

export enum Level {
    NoLevel = 'NOLEVEL',
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
}

export class WordBase {
    constructor(
        public readonly displayWord: string,
        public readonly syllables: Syllable[],
        public readonly phonetic: string,
        public readonly frequency: Frequency,
        public readonly rank: number,
        public readonly level: Level,
        public readonly translations: string[],
        public imgNr: number | null,
    ) {}
}

export abstract class Word {
    // NOTE needed for display in VirtualList
    // QUESTION speacial wrapper class would be cleaner..?!
    public english = false
    public first = false
    public searchedTranslations: string [] = []

    protected constructor(
        public readonly base: WordBase
    ) {}

    get syllCount() {
        return this.base.syllables.length;
    }

    get syllStr() {
        return this.base.syllables.map(s => s.syllable).join('·')
    }

    get word() {
        return this.base.syllables.map(s => s.syllable).join('')
    }

    // NOTE needed for display like on WordCard in dictionary
    // TODO className would be more appropriate
    abstract art: WordClass

    abstract id: WordId
}

export class Adjective extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Adjective
    }

    get id() {
        return `adj/${this.word}`
    }
}

export class Adverb extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Adverb
    }

    get id() {
        return `adv/${this.word}`
    }
}

export class Article extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Article
    }

    get id() {
        return `art/${this.word}`
    }
}

export class Conjunction extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Conjunction
    }

    get id() {
        return `conj/${this.word}`
    }
}

export class Interjection extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Interjection
    }

    get id() {
        return `inter/${this.word}`
    }
}

class Genus {
    static readonly MASKULINUM = new Genus('MASKULINUM', 'der');
    static readonly FEMININUM = new Genus('FEMININUM', 'die');
    static readonly NEUTRUM = new Genus('NEUTRUM', 'das');

    private constructor(
        private readonly key: 'MASKULINUM' | 'FEMININUM' | 'NEUTRUM',
        public readonly article: string
    ) {}

    toString(): 'MASKULINUM' | 'FEMININUM' | 'NEUTRUM' {
        return this.key;
    }
}

export function genus(s: 'MASKULINUM' | 'FEMININUM' | 'NEUTRUM' | 'der' | 'die' | 'das'): Genus {
    switch (s) {
        case 'MASKULINUM':
        case 'der':
            return Genus.MASKULINUM;
        case 'FEMININUM':
        case 'die':
            return Genus.FEMININUM;
        case 'NEUTRUM':
        case 'das':
            return Genus.NEUTRUM;
    }
}

export class Noun extends Word {
    constructor(
        public readonly base: WordBase,
        public readonly genus: Genus,
        public readonly plural: string,
        public readonly flexions: string,
    ) {
        super(base)
    }

    get art() {
        return WordClass.Noun
    }

    get id() {
        return `noun/${this.genus.article}-${this.word}`
    }
}

export class Num extends Word {
    constructor(
        public readonly base: WordBase,
        public readonly num: string
    ) {
        super(base)
    }

    get art() {
        return WordClass.Num
    }

    get id() {
        return `num/${this.word}`
    }
}

export class Preposition extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Preposition
    }

    get id() {
        return `prep/${this.word}`
    }
}

export class Pronoun extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Pronoun
    }

    get id() {
        return `pron/${this.word}`
    }
}

export class Verb extends Word {
    constructor(public readonly base: WordBase) {
        super(base)
    }

    get art() {
        return WordClass.Verb
    }

    get id() {
        return this.base.displayWord ? `verb/${this.base.displayWord.replaceAll(/\s/g, '-')}` : `verb/${this.word}`
    }
}
