import type {UserWord} from "$lib/classes/data/user-word";

export enum Action {
    Added = 'ADDED',
    Modified = 'MODIFIED',
    Removed = 'REMOVED',
}

export type UserWordChange = {
    userWord: UserWord
    changedLocally: boolean
    action: Action
}