export const HOST = 'https://vocabeo.com'
// export const ASSETS_HOST = 'https://vocabeo-assets.web.app'
// NOTE 'ERROR in initialize words&sentences incorrect header check' with bunny.net on prerendered routes like home
//  has to do with 'Allowed Referrers' restriction because prerender request comes from computer..?
export const ASSETS_HOST = 'https://assets.vocabeo.com'

export const REVIEW_COUNT_MAX = 20

export const PADDLE_CHECKOUT_CONTAINER_CLASS = 'checkout-container'

