import {shortestSearchedTranslationsFirst, sortEnglishSearchResultsAlphabetically, sortGermanAlpha} from './sort';
import {removeCharacters} from './removeCharacters';
import {WordConverter} from '$lib/classes/data/word-converter';
import type {UserWordsMap} from '$lib/classes/stores/get-user-words-map';
import {Num, type Word} from '$lib/classes/data/word';
import type {FilterSettingsValue} from '$lib/stores/filterSettings';
import {Sorting} from '$lib/classes/_types/sorting';
import {Status} from '$lib/classes/data/user-word';

const wordConverter = new WordConverter()

export const Filter = {

    filterUserWords(words: Word[], userWordsMap: UserWordsMap, status: string) {
        if (!status) return words

        return words.filter(word => {
            let userWord = userWordsMap[word.id]
            if (status === 'userWords') {
                return userWord
            } else if (status === 'rest') {
                return !userWord
            } else {
                return userWord?.status.toString() === status
            }
        })
    },

    filterSearchTerm(words: Word[], searchTerm: string) {
        if (!searchTerm) return words

        searchTerm = searchTerm.toLowerCase()

        let german = sortGermanAlpha(words.filter(w => {
            let word = w.word.toLowerCase()
            let displayWord = removeCharacters(w.base.displayWord).toLowerCase()
            return word.startsWith(searchTerm)
                || displayWord.startsWith(searchTerm)
                || displayWord.includes(` ${searchTerm}`)
        }))

        let english = words.filter(w => {
            let translations = w.base.translations.map(t => removeCharacters(t).toLowerCase())
            return translations.some(t => t.startsWith(searchTerm))
                || translations.some(t => t.includes(` ${searchTerm}`))
        })
        let deepCopy = english.map(e => wordConverter.fromJson(wordConverter.toJson(e)))
            .map(w => {
                w['searchedTranslations'] = w.base.translations.filter(t => {
                    t = removeCharacters(t).toLowerCase()
                    return t.startsWith(searchTerm) || t.includes(` ${searchTerm}`)
                })
                w['english'] = true
                return w
            })

        sortEnglishSearchResultsAlphabetically(deepCopy)
        shortestSearchedTranslationsFirst(deepCopy)

        return german.concat(deepCopy)
    },

    filterStaticOptions(words: Word[], filterObj: FilterSettingsValue) {

        if (filterObj.art) words = words.filter(w => w.art === filterObj.art)
        if (filterObj.level) words = words.filter(w => {
            if (filterObj.level === '-') {
                return w.base.level.toString() === 'NOLEVEL'
            } else {
                return w.base.level.toString() === filterObj.level
            }
        })
        if (filterObj.art === 'Num') (words as Num[]).sort((w1, w2) => parseInt(w1.num) - parseInt(w2.num))

        return words
    },

    sort(words: Word[], sorting: Sorting, userWordsMap: UserWordsMap) {

        // sorting = Sorting.Frequency >> nothing to do as words are sorted by frequency

        if (sorting === Sorting.Alphabetically) words = sortGermanAlpha(words)

        if (sorting === Sorting.DateSaved) {
            words = words.sort((w1, w2) => {
                const userWord1 = userWordsMap[w1.id]
                const userWord2 = userWordsMap[w2.id]
                if (!userWord1 && !userWord2) {
                    return 0
                } else if (!userWord1) {
                    return 1
                } else if (!userWord2) {
                    return -1
                } else {
                    return userWord1.added - userWord2.added
                }
            })
        }

        if (sorting === Sorting.DueToReview) {
            words = words.sort((w1, w2) => {
                const userWord1 = userWordsMap[w1.id]
                const userWord2 = userWordsMap[w2.id]
                if (!userWord1 && !userWord2) {
                    return 0
                } else if (!userWord1) {
                    return 1
                } else if (!userWord2) {
                    return -1
                } else if (userWord1.status === Status.Known && userWord2.status === Status.Known) {
                    return userWord1.toLearnNext - userWord2.toLearnNext
                } else if (userWord1.status === Status.Known && userWord2.status === Status.Learn) {
                    return 1
                } else if (userWord1.status === Status.Learn && userWord2.status === Status.Known) {
                    return -1
                } else {
                    return userWord1.toLearnNext - userWord2.toLearnNext
                }
            })
        }

        return words
    },

    markFirstEnglishWord(words: Word[]) {
        let firstEnglish = words.find(w => w.english)
        if (firstEnglish) {
            let index = words.indexOf(firstEnglish)
            words[index].first = true
        }
        return words
    }
}
