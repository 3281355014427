import {sortByIndex} from "../utils/sortByIndex.js";
import type {WordId} from "$lib/classes/data/_primitives";
import {type Sentence, WordLink} from "$lib/classes/data/sentence";

// TODO refactor filename !!

export type WordIdToSentences = Record<WordId, Sentence[]>

export let wordIdToSentences: WordIdToSentences = {}

export function initWordIdToSentences(sentences: Sentence[]) {

    sentences.forEach(sentence => {
        sentence.wordLinks.forEach(wordLink => {

            const hasTranlation = wordLink.t !== '-'
            const justOneOccurence = countOccurence(wordLink, sentence) === 1
            const notDisabled = wordLink.i !== -1

            if (hasTranlation && justOneOccurence && notDisabled) {

                let id = wordLink.id
                if (id === null) return

                let entry = wordIdToSentences[id]

                if (entry) {
                    wordIdToSentences[id] = [...wordIdToSentences[id], sentence]
                } else {
                    wordIdToSentences[id] = [sentence]
                }
            }
        })
    })

    // SORT

    const wordIds = Object.keys(wordIdToSentences)

    wordIds.forEach(id => {
        const sentences = wordIdToSentences[id]
        wordIdToSentences[id] = sortByIndex(sentences, id)
    })

}


function countOccurence(wordLink: WordLink, sentence: Sentence) {
    const wordLinkId = wordLink.id
    if (wordLinkId === null) return 0
    return sentence.wordLinks.reduce((sum, wL) => {
        let partId = wL.id
        if (partId === wordLinkId) {
            sum += 1
        }
        return sum
    }, 0)
}
