<script lang="ts">
    import {fly} from 'svelte/transition'

    export let savedWordsCount: string
</script>

<div id="word-count">
    {#key savedWordsCount}
        <div
             in:fly|global="{{ y: 10, duration: 700 }}"
        >
            {savedWordsCount}
        </div>
    {/key}
    <div class="txt">{savedWordsCount === '1' ? 'word' : 'words'}</div>
    <div class="txt">saved</div>
</div>

<style>
    #word-count {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 3rem;
        /*justify-content: space-evenly;*/
    }

    #word-count div:first-child {
        font-weight: bold;
        font-size: 1rem;
        line-height: 1;
        /*margin-bottom: auto;*/
    }

    .txt {
        font-size: .8rem;
        line-height: 1;
        margin-top: auto;
    }

    .txt + .txt {
        margin-top: revert;
        margin-bottom: auto;
    }
</style>