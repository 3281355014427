import {initializePaddle, type Paddle, type PaddleEventData, type Variant} from '@paddle/paddle-js';
import {getIpAddress} from '$lib/helper-fns/getIpAddress.js';
import type {Functions} from '$lib/classes/services/functions';
import type {User} from 'firebase/auth';

// TODO
// QUESTION would it be clearer to open/close paymentService with login/logout and pass uid/email there..?!?
//  argument against: paymentService is only needed for pricing/subscription route

export class PaymentService {
    private PaddleInstance: Paddle | null | undefined = null

    constructor(
        private readonly functions: Functions
    ) {}

    private async getPaddleInstance() {
        if (this.PaddleInstance === null) {
            try {
                this.PaddleInstance = await initializePaddle({
                    environment: import.meta.env.DEV ? 'sandbox' : 'production',
                    token: import.meta.env.VITE_PADDLE_CLIENT_SIDE_TOKEN,
                    // environment: 'production',
                    // token: 'live_8d0884dc8928d9e7fc4ec14eb1a',
                    pwCustomer: {},
                    checkout: {
                        settings: {
                            displayMode: 'overlay',
                            // frameTarget: PADDLE_CHECKOUT_CONTAINER_CLASS,
                            // frameInitialHeight: 500,
                            // frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;',
                            showAddDiscounts: false,
                            allowLogout: false,
                        }
                    },
                    // eventCallback: function (data) {
                    //     switch (data.name) {
                    //         case "checkout.closed":
                    //             break;
                    //         default:
                    //             console.log('Paddle eventCallback', data.name)
                    //     }
                    // }
                })
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throw new Error('ERROR initializing Paddle', {cause: error})
                }
            }
        }
        if (this.PaddleInstance === undefined) {
            throw new Error('ERROR initializing Paddle - #PaddleInstancePromise is undefined')
            // Should only happen server-side when !window
        }
        return this.PaddleInstance
    }

    async init() {
        await this.getPaddleInstance()
    }

    async authenticate(email: User['email']) {
        if (email === null) return

        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        try {
            const customerId = await this.functions.getCustomerId(email)
            if (customerId) {
                paddleInstance.Update({
                    pwCustomer: {
                        id: customerId
                    }
                })
            }
        } catch (error) {
            console.error('ERROR paymentService.authenticate', error)
        }
    }

    async getPrices() {
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return []

        const ipAddress = await getIpAddress()
        const request = {
            customerIpAddress: ipAddress,
            items: [
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_BRONZE_PRICE_ID,
                },
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_SILVER_PRICE_ID,
                },
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_GOLD_PRICE_ID,
                },
                {
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_PLATINUM_PRICE_ID,
                }
            ]
        }

        const result = await paddleInstance.PricePreview(request)
        // TODO build PriceInfo type here with all infos and pass that as one parameter to Price component
        return result.data.details.lineItems.map(lineItem => {
            return {
                price: lineItem.formattedUnitTotals.total,
                total: lineItem.unitTotals.total,
                // name: lineItem.price.name,
                // description: lineItem.price.description,
            }
        })
    }

    async updateCheckout(eventCallback: (eventData: PaddleEventData) => void) {
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        paddleInstance.Update({
            eventCallback: eventCallback
        })
    }

    async openCheckout(options: {
        email: string | undefined,
        uid: string | undefined,
        displayMode?: 'inline' | 'overlay',
        transactionId?: string
    }) {
        const uid = options.uid
        const email = options.email
        if (uid === undefined || email === undefined) {
            console.error('ERROR openCheckout - uid or vocabeoEmail is undefined')
            return
        }
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        const variant: Variant = options.transactionId ? 'multi-page' : 'one-page'

        const baseOptions = {
            settings: {
                variant: variant,
                displayMode: options.displayMode,
            },
            customer: {
                email: email
            },
            customData: {
                uid: uid,
                vocabeoEmail: email,
            },
        }

        if (options.transactionId) {
            paddleInstance.Checkout.open({
                ...baseOptions,
                transactionId: options.transactionId,
            })
        } else {
            paddleInstance.Checkout.open({
                ...baseOptions,
                items: [{
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_BRONZE_PRICE_ID,
                }],
            })
        }
    }
}