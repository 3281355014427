import {derived, type Readable} from 'svelte/store'

export enum DeviceType {
    Desktop = 'desktop',
    Mobile = 'mobile',
}

export type Device = Readable<DeviceType>

export function getDevice(pageWidth: Readable<number>) {
    return derived(pageWidth,
        $pageWidth => {
            if ($pageWidth > 1000) {
                return DeviceType.Desktop
            } else {
                return DeviceType.Mobile
            }
        }
    )
}