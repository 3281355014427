// QUESTION excludeId logic?!? Really needed for mobile filter options?
export function clickOutside(node: HTMLElement, excludeId: string | undefined = undefined) {

    const handleClick = (event: MouseEvent) => {
        const target = event.target
        if (target instanceof HTMLElement) {
            if (!node.contains(target) && !target.closest('#' + excludeId)) {
                event.stopPropagation()
                node.dispatchEvent(new CustomEvent('outclick'));
            }
        }
    };

    document.addEventListener('click', handleClick, true);

    return {
        destroy() {
            document.removeEventListener('click', handleClick, true);
        }
    };
}