import {derived, type Readable} from "svelte/store";
import type {WordId} from "$lib/classes/data/_primitives";
import {UserWord} from "$lib/classes/data/user-word";

export type UserWordsToLearn = Readable<UserWord[]>

//NOTE keep constructor function because of derived
export function getUserWordsToLearn(userWordsMap: Readable<Record<WordId, UserWord>>): Readable<UserWord[]> {
    return derived(userWordsMap, (value, set) => {

        set(calculateUserWordsToLearn(value))

        const interval = setInterval(() => {
            set(calculateUserWordsToLearn(value))
        }, 60000)

        return () => clearInterval(interval)
    })
}

function calculateUserWordsToLearn(uWM: Record<WordId, UserWord>) {
    const userWords = Object.values(uWM)
    const learnStatus = userWords.filter(userWord => {
        const status = userWord.status.toString()
        const dueToLearn = userWord.toLearnNext <= Date.now()
        return status === 'LEARN' && dueToLearn
    })
    return learnStatus.sort((uW1, uW2) => uW1.toLearnNext - uW2.toLearnNext)
}