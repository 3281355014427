import type {
    WordId,
    SentenceId,
    ImgNr,
    SentenceWord,
    Index,
    Translation,
} from "$lib/classes/data/_primitives";

export class PunctMark {
    constructor(
        readonly pmark: string
    ) {}
}

// TODO in prod id/t/i should never be null!
export class WordLink {
    constructor(
        public word: SentenceWord,
        public id: WordId | null,
        public t: Translation | null,
        public i: Index | null,
    ) {}
}

type SentencePart = WordLink | PunctMark;

export class Sentence {
    constructor(
        public readonly id: SentenceId,
        public parts: SentencePart[],
        public imgNr: ImgNr | null,
        public t: Translation | null,
    ) {}

    get sentence(): string {
        return this.parts.map(part => {
            if (part instanceof WordLink) {
                return part.word;
            } else {
                return part.pmark;
            }
        }).join('')
    }

    get wordLinks(): WordLink[] {
        return this.parts.filter(part => part instanceof WordLink);
    }

    wordLinksWithId(id: WordId): WordLink[] {
        return this.wordLinks.filter(wordLink => wordLink.id === id);
    }

    deletePart(index: number) {
        this.parts.splice(index, 1);
    }
}