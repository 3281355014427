<script lang="ts">
    import Logo from '$lib/components/assets/Logo.svelte';
</script>

<div id="remote-changes-wrapper">
    <div>

        <Logo size="midi"/>

        <p>
            Changes were detected from a different tab or device. Please
        </p>

        <button on:click={() => location.reload()}>reload the page</button>

    </div>
</div>

<style>
    #remote-changes-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: var(--color-light);
    }

    #remote-changes-wrapper > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(-10vh);
        /*border: 1px solid magenta;*/
    }

    p {
        margin: 2rem 0;
        text-align: center;
        max-width: 26ch;
    }

    button {
        padding: .8rem 1rem;
        font-weight: var(--font-emph);
        background: var(--color-accent);
        color: white;
    }


</style>