import {derived, type Readable} from 'svelte/store'
import {type FilterSettings} from '$lib/stores/filterSettings';

export type FilterSettingsFiltered = Readable<boolean>

export function getFilterSettingsFiltered(filterSettings: FilterSettings) {
    return derived(filterSettings,
        $filterSettings => {
            return !Object.entries($filterSettings)
                .filter(([key, _]) => key !== 'search' && key !== 'sortAlph')
                .every(([_, prop]) => prop === '')
        }
    )
}