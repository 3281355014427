import {get, writable} from "svelte/store";
import {logInProduction} from "$lib/utils/logInProduction";
import type {Navigation} from "@sveltejs/kit";
import {navigating} from "$app/stores";
import type {Database} from "$lib/classes/services/database";
import {timer} from "$lib/utils/timer";

export type ReviewCountDoc = Record<string, number>

export class ReviewCount {
    private readonly store = writable(0);
    readonly subscribe = this.store.subscribe;

    private key = this.keyOfToday

    private get keyOfToday() {
        const now = new Date()
        const month = String(now.getMonth() + 1).padStart(2, '0')
        const day = String(now.getDate()).padStart(2, '0')
        return `${month}-${day}`
    }

    private get keyChanged() {
        return this.key !== this.keyOfToday
    }

    private uid: string | null = null
    private unsubNavigatingSubscription = () => {}
    private boundHandleVisibilityChange = () => {}
    private interval: ReturnType<typeof setInterval> | undefined = undefined

    constructor(
        private readonly database: Database
    ) {
    }

    private async checkKey() {
        if (this.keyChanged) {
            logInProduction('NEW DAY')
            this.key = this.keyOfToday
            await this.setAndSave(0)
        }
    }

    private startCheckKeyInterval() {
        //started
        // when init() called on '/learn'
        // when navigating to route '/learn'
        // when document.visibilityState == 'visible' && pathname === '/learn'
        if (!this.interval) {
            this.interval = setInterval(() => this.checkKey(), 5000)
        }
    }

    private stopCheckKeyInterval() {
        clearInterval(this.interval)
        this.interval = undefined
    }

    private handleNavigatingChange(navigating: Navigation | null) {
        if (navigating === null) return
        const from = navigating?.from?.route.id
        const to = navigating?.to?.route.id
        if (to === '/learn') {
            this.startCheckKeyInterval()
        } else if (from === '/learn') {
            this.stopCheckKeyInterval()
        }
    }

    private handleVisibilityChange() {
        if (location.pathname !== '/learn') return
        if (document.visibilityState === 'visible') {
            this.startCheckKeyInterval()
        } else if (document.visibilityState === 'hidden') {
            this.stopCheckKeyInterval()
        }
    }

    private async setAndSave(count: number) {
        this.store.set(count)
        if (this.uid) {
            try {
                await this.database.setReviewCountDoc(this.uid, {[this.key]: count})
            } catch (error) {
                console.error('ERROR writing reviewCountDoc', error)
            }
        }
    }

    async init(userId: string): Promise<void> {
        const timer_reviewCount = timer('reviewCount.init')

        this.uid = userId
        const key = this.keyOfToday
        this.key = key

        // NOTE subscription to reviewCountDoc not possible because of db path collection/year/collection
        const doc = await this.database.getReviewCountDoc(userId)
        if (doc === null || doc[key] === undefined) {
            await this.setAndSave(0)
            logInProduction('rc init >> 0')
        } else {
            const savedCount = doc[key]
            this.store.set(savedCount)
            logInProduction(`rc init >> ${savedCount}`)
        }

        const reviewCountInstance = this
        this.unsubNavigatingSubscription = navigating.subscribe(this.handleNavigatingChange.bind(reviewCountInstance))
        this.boundHandleVisibilityChange = this.handleVisibilityChange.bind(reviewCountInstance)
        document.addEventListener('visibilitychange', this.boundHandleVisibilityChange)

        if (location.pathname === '/learn') this.startCheckKeyInterval()

        timer_reviewCount()
    }

    async addOne() {
        // NOTE addOne will be blocked by RemoteChanges
        await this.checkKey()

        const currentCount = get(this.store)
        const newCount = currentCount + 1
        await this.setAndSave(newCount)
        logInProduction(`rc >> ${currentCount}+1 >> ${newCount}`)
    }

    terminate() {
        this.uid = null
        this.unsubNavigatingSubscription()
        document.removeEventListener('visibilitychange', this.boundHandleVisibilityChange)
        this.stopCheckKeyInterval()
    }
}