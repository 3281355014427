import {PunctMark, Sentence, WordLink} from "$lib/classes/data/sentence";
import type {SentenceJson} from "$lib/classes/data/sentence_zod";

export class SentenceConverter {
    fromJson(sentenceJson: SentenceJson): Sentence {
        return new Sentence(
            sentenceJson.id,
            sentenceJson.parts.map(part => {
                if (part['@type'] === 'WordLink') {
                    return new WordLink(part.word, part.id, part.t, part.i);
                } else {
                    return new PunctMark(part.pmark);
                }
            }),
            sentenceJson.imgNr,
            sentenceJson.t,
        );
    }
}