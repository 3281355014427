import type {Sentence} from "$lib/classes/data/sentence";
import type {SentenceId} from "$lib/classes/data/_primitives";

export type SentenceIdToSentence = Record<SentenceId, Sentence>

export let sentenceIdToSentence: SentenceIdToSentence = {}

export function initSentenceIdToSentence(allSentences: Sentence[]) {
    // sentenceIdToSentence = allSentences.reduce((map, sentence) => {
    //     map[sentence.id] = sentence
    //     return map
    // }, {})
    allSentences.forEach(sentence => {
        sentenceIdToSentence[sentence.id] = sentence
    })
}