<!--suppress HtmlUnknownAttribute -->
<script lang="ts">
    import {Sorting} from '$lib/classes/_types/sorting';

    export let value: Sorting
    export let onChange: (newValue: Sorting) => void

    const setName = 'sorting'

    const options: { value: Sorting, label: string }[] = [
        {value: Sorting.Frequency, label: 'by frequency'},
        {value: Sorting.Alphabetically, label: 'alphabetically'},
        {value: Sorting.DateSaved, label: 'date saved'},
        {value: Sorting.DueToReview, label: 'next review'},
    ]
</script>

<div class="setName">
    Sort
    <slot></slot>
</div>

<div class="sorting"
     data-testid="sorting"
>
    {#each options as option, index}
        <label for={`${setName}-${index}`}
               class:selected={option.value === value}
               class:border-right="{index%2 === 0}"
               class:border-bottom="{index <= 1}"
               class:border-top="{index >= 2}"
        >
            {option.label}
            <input type="radio"
                   id={`${setName}-${index}`}
                   name="{setName}"
                   value={option.value}
                   bind:group={value}
                   on:change={() => onChange(option.value)}
            >
        </label>
    {/each}
</div>

<style>
    input:focus-visible {
        outline: none;
    }

    .setName {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: var(--margin-big);
        margin-bottom: var(--margin-medium);
        font-size: .9rem;
        font-weight: var(--font-emph);
    }

    .sorting {
        display: grid;
        flex-shrink: 0;
        grid-template-columns: 1fr 1fr;
        border-radius: var(--border-radius);
        overflow: hidden;
        font-size: .88rem;
    }

    input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    label {
        position: relative;
        display: grid;
        place-content: center;
        padding-block: .2rem;
        background: var(--color-light);
        border: 1px solid var(--color-light);
        user-select: none;
        cursor: pointer;
        transition: background 200ms, border 200ms;
    }

    .border-right {
        border-right: 2px solid var(--color-dark);
    }

    .border-bottom {
        border-bottom: 1px solid var(--color-dark);
    }

    .border-top {
        border-top: 1px solid var(--color-dark);
    }

    .selected {
        font-weight: var(--font-emph);
        color: var(--color-light);
        background: var(--color-accent);
        border-color: var(--color-accent);
    }

    @media (prefers-color-scheme: dark) {
        .selected {
            color: var(--color-dark-2);
        }
    }

</style>