// NOTE function excluded from rollup strip >> logs in production
export function timer(timerName: string) {
    let start: number | null = Date.now()
    return () => {
        // only log once
        if (start) {
            const end = Date.now()
            console.log(`${timerName}:`, end - start)
            start = null
        }
    }
}
